import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLink,
  CRow,
} from "@coreui/react";
import { AxiosError } from "axios";
import _get from "lodash/get";
import _pick from "lodash/pick";
import * as qs from "query-string";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { UserData, getUserDataSelector } from "../../../../auth";
import { isAllowedAccess } from "../../../../auth/utils";
import { QuestButton } from "../../../../common/components/QuestButton";
import { DATATABLE_MAX_ROWS } from "../../../../common/constants/datatable";
import useDidMount from "../../../../common/hooks/useDidMount";
import { processErrorMessage } from "../../../../error-handler/utils";
import { setNotification } from "../../../../notification/actions/creators";
import { useNotificationDispatch } from "../../../../notification/dispatchers";
import RefClientListFilter, {
  FilterData,
} from "../../../components/RefClientListFilter";
import RefClientTable from "../../../components/RefClientTable";
import { PERMISSION_IAM } from "../../../constants/permissions";
import ClientService from "../../../services/ClientService";
import {
  RefClient,
  RefClientListQueryParams,
  RefClientListParams,
} from "../../../types/ref-client";
import "./index.scss";

const ClientList: React.FC = () => {
  const history = useHistory();
  const loggedInUser = useSelector(getUserDataSelector);
  const notifDispatch = useNotificationDispatch();
  const location = useLocation();
  const queryParams: RefClientListQueryParams = useMemo(() => {
    return qs.parse(location.search, {
      parseNumbers: true,
      arrayFormat: "comma",
    });
  }, [location.search]);
  const [clients, setClients] = useState<{
    data: RefClient[];
    count: number;
    loading: boolean;
  }>({
    data: [],
    count: 0,
    loading: false,
  });
  const filterData = useMemo(
    () => ({
      keyword: queryParams.keyword || "",
      bdm: queryParams.bdm
        ? {
            value: queryParams.bdm[0],
            label: queryParams.bdm[1],
          }
        : null,
    }),
    [queryParams]
  );

  useEffect(() => {
    if (
      !isAllowedAccess(loggedInUser as UserData, [
        PERMISSION_IAM["IAM.REFERRER.READ"],
      ])
    ) {
      return history.replace(`/`);
    }
  }, [loggedInUser, history]);

  const setQueryParams = (overrideValues: RefClientListQueryParams) => {
    const newQueryParams = qs.stringify(
      {
        ...queryParams,
        ...overrideValues,
      },
      {
        arrayFormat: "comma",
      }
    );
    history.push("?" + newQueryParams);
  };

  const getClients = useCallback(
    async (queryParams: RefClientListQueryParams) => {
      setClients({
        data: [],
        count: 0,
        loading: true,
      });

      let clients: {
        data: RefClient[];
        count: number;
      } = {
        data: [],
        count: 0,
      };

      try {
        const params: RefClientListParams = {
          ..._pick(queryParams, ["keyword", "limit"]),
          offset: queryParams.page,
          bdmId: queryParams?.bdm?.[0],
        };

        const resp = await ClientService.listRefClients(params);
        clients = {
          data: resp.data,
          count: resp.count ?? 0,
        };
      } catch (error) {
        const message = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            body: message,
            className: "qst-notif-danger",
          })
        );
      } finally {
        setClients({
          ...clients,
          loading: false,
        });
      }
    },
    [notifDispatch]
  );

  const onFilterChangeHandler = (filterData: FilterData) => {
    setQueryParams({
      keyword: filterData.keyword || undefined,
      bdm: filterData.bdm
        ? [filterData.bdm?.value, filterData.bdm?.label]
        : undefined,
      page: undefined,
    });
  };

  const onClearFilersHandler = () => {
    history.push("?");
  };

  useDidMount(() => {
    getClients(queryParams);
  });

  useEffect(() => {
    getClients(queryParams);
  }, [getClients, queryParams]);

  return (
    <>
      <CRow className="quest-page-header-section">
        <CCol xs={12} className="align-items-center d-flex">
          <h2 className="quest-header-text">Referrer company filters</h2>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12">
          <CCard className="quest-card">
            <CCardHeader className="d-flex justify-content-end">
              <CLink to="/iam/ref-clients/new/admin">
                <QuestButton data-testid="btn-add-new-client">
                  Add a new referrer company
                </QuestButton>
              </CLink>
            </CCardHeader>
            <CCardBody className="quest-datatable client-list">
              <h4 className="f-bold f-quest-navy">Referrer company filters:</h4>
              <RefClientListFilter
                filterData={filterData}
                onFilterChange={onFilterChangeHandler}
                onClearFilers={onClearFilersHandler}
                className="mb-2"
              />
              <RefClientTable
                data={clients.data}
                count={clients.count}
                loading={clients.loading}
                page={_get(queryParams, "page", 1)}
                perPage={_get(queryParams, "limit", DATATABLE_MAX_ROWS)}
                onParameterChange={setQueryParams}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default ClientList;
