import React, { useCallback } from "react";
import { AxiosError } from "axios";
import { GroupBase, Options } from "react-select";
import ReactSelectAsync, { AsyncProps } from "react-select/async";
import { useDebouncedCallback } from "use-debounce";
import { processErrorMessage } from "../../error-handler/utils";
import { actionCreator, dispatch } from "../../notification";
import ClientService from "../services/ClientService";

export type TClient = { label: string; value: number };

export type TClientQuery<T = TClient> = (
  keyword: string,
  cb: (options: Options<T>) => void
) => void;

const ClientDropdown: ReactSelectAsync = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: AsyncProps<Option, IsMulti, Group>
) => {
  const { useNotificationDispatch } = dispatch;
  const { setNotification } = actionCreator;
  const notifDispatch = useNotificationDispatch();
  const getOptions: TClientQuery = useCallback(
    async (keyword, cb) => {
      try {
        const clients = await ClientService.listClients({ keyword });
        cb(
          clients.data.map((client) => {
            return {
              label: client.name,
              value: client.id,
            };
          })
        );
      } catch (error) {
        const message = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            body: message,
            className: "qst-notif-danger",
          })
        );
      }
    },
    [notifDispatch, setNotification]
  );

  const debouncedGetOptions = useDebouncedCallback((input, cb) => {
    getOptions(input, cb);
  }, 700);

  return (
    <ReactSelectAsync
      placeholder="Filter by client"
      isClearable={true}
      defaultOptions={true}
      loadOptions={debouncedGetOptions}
      {...props}
    />
  );
};

export default ClientDropdown;
