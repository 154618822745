import React, { useCallback, useEffect, useState } from "react";
import {
  DownloadOutlined,
  MoreOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CModal,
  CSpinner,
  CTooltip,
} from "@coreui/react";
import { AxiosError } from "axios";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import {
  BUTTON_COLORS,
  QuestButton,
} from "../../../../../common/components/QuestButton";
import { dateFormat } from "../../../../../common/utils/date";
import { testIdWrapper } from "../../../../../common/utils/testing";
import { processErrorMessage } from "../../../../../error-handler/utils";
import { setNotification } from "../../../../../notification/actions/creators";
import { useNotificationDispatch } from "../../../../../notification/dispatchers";
import { getExtras } from "../../../../actions/creators/extras";
import {
  APPLICATION_EXTRA_KEYS,
  dateDisplayFormat,
} from "../../../../constants/applicationAdmin";
import { useExtraDispatch } from "../../../../dispatchers";
import ApplicationDocumentService from "../../../../services/ApplicationDocumentService";
import { PrivacyFormLog } from "../../../../types/ApplicationDocument";
import "./PrivacyForm.scss";

const logCell = (content: string, testId: string) => {
  return (
    <CTooltip content={content}>
      <div className="text-truncate" data-testid={testId}>
        {content}
      </div>
    </CTooltip>
  );
};

const logTableColumns: IDataTableColumn<PrivacyFormLog>[] = [
  {
    selector: "Date",
    name: "Date",
    cell(log, i) {
      return testIdWrapper(
        dateFormat(new Date(log.Date), dateDisplayFormat),
        `log-date-${i}`
      );
    },
    width: "200px",
  },
  {
    selector: "User",
    name: "User",
    cell(log, i) {
      return logCell(log.Name, "log-user-" + i);
    },
    width: "200px",
  },
  {
    selector: "Role",
    name: "Role",
    cell(log, i) {
      return logCell(log.Role, "log-role-" + i);
    },
    width: "150px",
  },
  {
    selector: "Action",
    name: "Action",
    cell(log, i) {
      return logCell(log.Action, "log-action-" + i);
    },
    width: "200px",
  },
];

type ResendPrivacyFormConfirmationProps = {
  date?: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const ResendPrivacyFormConfirmation: React.FC<ResendPrivacyFormConfirmationProps> = (
  props: ResendPrivacyFormConfirmationProps
) => {
  const { onCancel, onConfirm, isOpen, date } = props;
  const dateSent = date
    ? dateFormat(new Date(date as string), dateDisplayFormat)
    : "";

  return (
    <CModal
      className="confirm-modal"
      show={isOpen}
      onClose={onCancel}
      closeOnBackdrop={false}
      data-testid="confirm-resend-modal"
    >
      <div className="m-3 mx-5">
        <h3>
          <WarningOutlined className="warn-icon" />
          Sen new privacy form?
        </h3>
        <div className="mb-3">
          You have previously Send a privacy form on{" "}
          {isOpen && <div data-testid="modal-date-sent">{dateSent}</div>}
        </div>
        <div>This action will void the previous form and send a new one.</div>
      </div>
      <div className="m-3 text-center">
        <QuestButton
          color={BUTTON_COLORS.SECONDARY}
          onClick={onCancel}
          className="mr-3"
          type="button"
          data-testid="cancel-resend-btn"
        >
          Cancel
        </QuestButton>
        <QuestButton
          color={BUTTON_COLORS.COMMIT}
          onClick={onConfirm}
          className="mr-3"
          type="button"
          data-testid="confirm-resend-btn"
        >
          Send
        </QuestButton>
      </div>
    </CModal>
  );
};

type LogsModalProps = {
  applicationId: string;
  onCancel: () => void;
  isOpen?: boolean;
};

const LogsModal: React.FC<LogsModalProps> = (props: LogsModalProps) => {
  const { applicationId, onCancel, isOpen } = props;
  const notifDispatch = useNotificationDispatch();
  const [logs, setLogs] = useState<{
    data: PrivacyFormLog[];
    isLoading: boolean;
  }>({
    data: [],
    isLoading: false,
  });

  const getLogList = useCallback(async () => {
    let data: PrivacyFormLog[] = [];
    setLogs({
      data: [],
      isLoading: true,
    });

    try {
      const result = await ApplicationDocumentService.getPrivacyFormLogs(
        applicationId
      );
      data = result.data;
    } catch (e) {
      const error = e as AxiosError;
      const errorMsg = processErrorMessage(error);
      notifDispatch(
        setNotification({
          body: errorMsg,
          className: "qst-notif-danger",
        })
      );
    }

    setLogs({
      data,
      isLoading: false,
    });
  }, [applicationId, setLogs, notifDispatch]);

  useEffect(() => {
    if (isOpen) {
      getLogList();
    }
  }, [getLogList, isOpen]);

  useEffect(() => {
    setLogs({
      data: [],
      isLoading: false,
    });
  }, []);

  let content = null;

  if (logs.isLoading) {
    content = (
      <CSpinner
        size="md"
        data-testid="log-loading-icon"
        className="d-block my-1 mx-auto"
      />
    );
  } else if (!logs.data.length) {
    content = (
      <div className="text-center" data-testid="log-no-result">
        No results found
      </div>
    );
  } else {
    content = (
      <div data-testid="log-list">
        <DataTable
          className="log-list"
          columns={logTableColumns}
          data={logs.data}
          responsive={false}
          noHeader
        />
      </div>
    );
  }

  return (
    <CModal
      className="log-modal"
      show={isOpen}
      onClose={onCancel}
      closeOnBackdrop={false}
    >
      <div className="py-3 px-4">
        <h2>Privacy form logs</h2>
        {isOpen && (
          <div data-testid="log-modal-content" className="pb-3">
            {content}
          </div>
        )}
        <div className="m-3 text-center">
          <QuestButton
            color={BUTTON_COLORS.SECONDARY}
            onClick={onCancel}
            type="button"
            data-testid="log-close-btn"
          >
            Close
          </QuestButton>
        </div>
      </div>
    </CModal>
  );
};

export type PrivacyFormProps = {
  applicationId: string;
  disabled?: boolean;
  data?: {
    dateSent: string;
    dateSigned: string | null;
  };
};

const PrivacyForm: React.FC<PrivacyFormProps> = (props: PrivacyFormProps) => {
  const { applicationId, disabled, data } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [resendModal, setResendModal] = useState(false);
  const [logModal, setLogModal] = useState(false);
  const extrasDispatch = useExtraDispatch();
  const notifDispatch = useNotificationDispatch();
  const signed = Boolean(data?.dateSigned);
  const isSent = Boolean(data?.dateSent);
  const pending = isSent && !signed;
  const formattedDateSent = data?.dateSent
    ? dateFormat(new Date(data?.dateSent), dateDisplayFormat)
    : "";
  const dateSent = isSent && (
    <div className="date" data-testid="date-sent">
      Sent on {formattedDateSent}
    </div>
  );
  const downloadIcon = signed && !isLoading && (
    <DownloadOutlined className="download-icon" data-testid="download-icon" />
  );
  const loadingIcon = isLoading && (
    <CSpinner
      size="sm"
      data-testid="privacy-form-loading-icon"
      className="my-1 mx-2"
    />
  );

  const resendPrivacyForm = async () => {
    setResendModal(false);
    sendPrivacyForm();
  };

  const sendPrivacyForm = async () => {
    setIsLoading(true);
    try {
      await ApplicationDocumentService.sendPrivacyForm(applicationId);
      extrasDispatch(getExtras(applicationId, APPLICATION_EXTRA_KEYS));
      notifDispatch(
        setNotification({
          body: "Privacy form has been successfully sent.",
          className: "qst-notif-success",
        })
      );
    } catch (e) {
      const error = e as AxiosError;
      const errorMsg = processErrorMessage(error);
      notifDispatch(
        setNotification({
          body: errorMsg,
          className: "qst-notif-danger",
        })
      );
    }
    setIsLoading(false);
  };

  const download = async () => {
    setIsLoading(true);
    try {
      const result = await ApplicationDocumentService.downloadPrivacyForm(
        applicationId
      );
      window.open(result.data, "_blank");
    } catch (e) {
      const error = e as AxiosError;
      const errorMsg = processErrorMessage(error);
      notifDispatch(
        setNotification({
          body: errorMsg,
          className: "qst-notif-danger",
        })
      );
    }
    setIsLoading(false);
  };

  const sendReminder = async () => {
    setIsLoading(true);
    try {
      await ApplicationDocumentService.sendPrivacyFormReminder(applicationId);
      notifDispatch(
        setNotification({
          body: "Privacy form reminder has been successfully sent.",
          className: "qst-notif-success",
        })
      );
    } catch (e) {
      const errorMsg = processErrorMessage(e as AxiosError);
      notifDispatch(
        setNotification({
          body: errorMsg,
          className: "qst-notif-danger",
        })
      );
    }
    setIsLoading(false);
  };

  const onButtonClick = () => {
    if (isLoading) return;

    if (signed) {
      download();
    } else {
      sendPrivacyForm();
    }
  };

  const privacyFormButton = (
    <>
      <button
        onClick={onButtonClick}
        disabled={disabled || pending || isLoading}
        data-testid={signed ? "download-privacy-form" : "send-privacy-form"}
        className="quest-button primary"
      >
        {loadingIcon}
        Privacy Form
        {downloadIcon}
      </button>
    </>
  );

  return (
    <div>
      <div className="pf-btn-cont">
        {privacyFormButton}
        {isSent && (
          <CDropdown className="pf-actions">
            <CDropdownToggle
              caret={false}
              disabled={disabled}
              data-testid="more-action"
            >
              <MoreOutlined className="more-icon" />
            </CDropdownToggle>
            <CDropdownMenu
              className="quest-dropdown-menu"
              placement="bottom-end"
            >
              <CDropdownItem
                onClick={() => setLogModal(true)}
                data-testid="view-log-btn"
              >
                View log
              </CDropdownItem>
              {!signed && (
                <CDropdownItem
                  disabled={isLoading}
                  onClick={sendReminder}
                  data-testid="send-reminder-btn"
                >
                  Send reminder
                </CDropdownItem>
              )}
              <CDropdownItem
                disabled={isLoading}
                onClick={() => setResendModal(true)}
                data-testid="resend-btn"
              >
                Send new privacy form
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        )}
      </div>
      {dateSent}
      <LogsModal
        applicationId={applicationId}
        onCancel={() => setLogModal(false)}
        isOpen={logModal}
      />
      <ResendPrivacyFormConfirmation
        isOpen={resendModal}
        date={formattedDateSent}
        onConfirm={resendPrivacyForm}
        onCancel={() => setResendModal(false)}
      />
    </div>
  );
};

export default PrivacyForm;
