import { User } from "../../user/types/user";

export enum Status {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  CLOSED = "CLOSED",
}

export type RefClient = {
  id: number;
  accreditationId: string;
  entityId: number;
  name: string;
  bdmId: number;
  status: Status;
  agentCount: string;
  bdm: User;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type RefClientPayload = {
  name: string;
  status?: Status;
};

export type RefClientListQueryParams = Partial<{
  limit: number;
  order: string;
  page: number;
  keyword: string;
  bdm: [number, string];
}>;

export type RefClientListParams = {
  limit?: number;
  offset?: number;
  order?: string;
  keyword?: string;
  bdmId?: number;
  includeDerivedAccess?: boolean;
};

export interface RefClientFormData {
  name: string;
  status: Status;
}

export interface ApplicationConfig {
  financierRateDiscount: number;
  contractDcocument: {
    default: string;
    options: string[];
  };
}
