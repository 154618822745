export const BOOLEAN_YES = true;
export const BOOLEAN_NO = false;
export const BOOLEAN_OPTIONS = [
  {
    value: BOOLEAN_YES,
    label: "Yes",
  },
  {
    value: BOOLEAN_NO,
    label: "No",
  },
];
