import React, { useCallback, useEffect } from "react";
import { CCol, CRow, CLabel, CInput, CSelect, CForm } from "@coreui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import { AxiosError } from "axios";
import cs from "classnames";
import {
  SubmitHandler,
  Controller,
  useForm,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { NumberField } from "../../../common/components/NumberField";
import { QuestButton } from "../../../common/components/QuestButton";
import { FormMode } from "../../../common/types/Form";
import { processErrorMessage } from "../../../error-handler/utils";
import { setNotification } from "../../../notification/actions/creators";
import { useNotificationDispatch } from "../../../notification/dispatchers";
import { STATUS } from "../../constants/client";
import { defaultRefUser } from "../../constants/ref-user";
import ClientService from "../../services/ClientService";
import { RefUserFormData, ValidRefUserFormData } from "../../types/ref-user";
import { refUsersonSchema } from "../../validations/ref-user";
import ClientDropdown, { TClientQuery } from "../ClientDropdown";

type TextFieldProps = {
  name: keyof RefUserFormData;
  label: string;
  testId: string;
  required?: boolean;
};
const TextField: React.FC<TextFieldProps> = ({
  name,
  label,
  testId,
  required = true,
}: TextFieldProps) => {
  const { register, errors: formErrors } = useFormContext<RefUserFormData>();
  const error = formErrors[name];

  return (
    <CRow>
      <CCol
        xl={11}
        xs={12}
        className={cs("mb-3", {
          "with-error": error,
        })}
      >
        <CLabel className={cs({ required })}>{label}</CLabel>
        <CInput
          name={name}
          innerRef={register()}
          data-testid={testId}
          autoComplete="off"
        />
        {error && (
          <span className="validation-error" data-testid={`${testId}-error`}>
            {error?.message}
          </span>
        )}
      </CCol>
    </CRow>
  );
};

export type RefUserFormProps = {
  data?: RefUserFormData;
  mode?: FormMode;
  onSubmit?: (data: ValidRefUserFormData) => Promise<void>;
  loading?: boolean;
};
const RefUserForm: React.FC<RefUserFormProps> = (props: RefUserFormProps) => {
  const { mode = FormMode.CREATE } = props;
  const notifDispatch = useNotificationDispatch();
  const formObject = useForm<RefUserFormData>({
    resolver: joiResolver(refUsersonSchema),
    defaultValues: defaultRefUser,
  });
  const {
    register,
    handleSubmit,
    errors: formErrors,
    control,
    reset,
  } = formObject;
  const onSubmit: SubmitHandler<ValidRefUserFormData> = async (data) => {
    props.onSubmit?.(data);
  };

  useEffect(() => {
    reset(props.data);
  }, [reset, props.data]);

  const getRefClientOptions: TClientQuery = useCallback(
    async (keyword, cb) => {
      try {
        const clients = await ClientService.listRefClients({
          keyword,
        });

        cb(
          clients.data.map((client) => {
            return {
              label: client.name,
              value: client.id,
            };
          })
        );
      } catch (error) {
        const message = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            body: message,
            className: "qst-notif-danger",
          })
        );
      }
    },
    [notifDispatch]
  );

  const debouncedGetRefClientOptions = useDebouncedCallback((input, cb) => {
    getRefClientOptions(input, cb);
  }, 700);

  return (
    <FormProvider {...formObject}>
      <CForm
        className="row mb-3"
        data-testid="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CCol xl={6}>
          <CRow>
            <CCol xl={11} xs={12} className="mb-3">
              <CLabel>Referrer Company</CLabel>
              <Controller
                name="client"
                control={control}
                render={({ onChange, value }) => (
                  <ClientDropdown
                    id="client"
                    name="client"
                    value={value}
                    onChange={onChange}
                    loadOptions={debouncedGetRefClientOptions}
                  />
                )}
              />
              {formErrors.client && (
                <span className="validation-error" data-testid="client-error">
                  {formErrors.client.message}
                </span>
              )}
            </CCol>
          </CRow>
          <TextField name="firstName" label="First Name" testId="first-name" />
          <TextField name="lastName" label="Last Name" testId="last-name" />
          <TextField name="email" label="Email" testId="email" />
          <CRow>
            <CCol
              xl={11}
              xs={12}
              className={cs("mb-3", {
                "with-error": formErrors.mobile,
              })}
            >
              <CLabel className="required">Mobile</CLabel>
              <Controller
                name="mobile"
                control={control}
                render={({ onChange, value }) => (
                  <NumberField
                    name="mobile"
                    inputMode="tel"
                    onValueChange={(values) => {
                      onChange(values.value);
                    }}
                    value={value}
                    format="## #### ####"
                    data-testid="mobile"
                  />
                )}
              />
              {formErrors.mobile && (
                <span className="validation-error" data-testid="mobile-error">
                  {formErrors.mobile.message}
                </span>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol xl={11} xs={12}>
              <CLabel className="required">Status</CLabel>
              <CSelect name="status" innerRef={register()} data-testid="status">
                {Object.entries(STATUS).map(([key, label]) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </CSelect>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs={12}>
              <QuestButton
                disabled={Boolean(props.loading)}
                data-testid="submit"
                className="mt-4"
              >
                {mode === FormMode.CREATE ? "Create" : "Update"}
              </QuestButton>
            </CCol>
          </CRow>
        </CCol>
      </CForm>
    </FormProvider>
  );
};

export default RefUserForm;
