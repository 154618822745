import "./index.scss";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CLink,
  CRow,
} from "@coreui/react";
import qs from "query-string";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { UserData, getUserDataSelector } from "../../../auth";
import { isAllowedAccess } from "../../../auth/utils";
import ArrowRedRight from "../../../common/assets/images/arrow-right-red.svg";
import Badge, { BADGE_COLORS } from "../../../common/components/Badge";
import Loading from "../../../common/components/Loading";
import { QuestButton } from "../../../common/components/QuestButton";
import useDidMount from "../../../common/hooks/useDidMount";
import { parseSortingObject } from "../../../common/utils/dataTable";
import { dateFormat } from "../../../common/utils/date";
import { getErrorMessageSelector } from "../../../error-handler";
import { PERMISSION_IAM } from "../../../iam/constants/permissions";
import { cloneApplication } from "../../actions/creators/applicationForm";
import { getApplicationList } from "../../actions/creators/applicationList";
import {
  APPLICATION_STATUS_COLORS,
  APPLICATION_PUBLIC_STATUS_LABELS,
  APPLICATION_STATUS_GROUP,
  APPLICATION_PUBLIC_STATUSES,
} from "../../constants/applicationStatuses";
import {
  useApplicationFormDispatch,
  useApplicationListDispatch,
} from "../../dispatchers";
import {
  getCloneApplicationLoadingSelector,
  getClonedApplicationIdSelector,
} from "../../selectors/applicationForm";
import {
  getApplicationListSelector,
  getLoadingSelector,
  getApplicationCountSelector,
} from "../../selectors/applicationList";
import {
  ApplicationListQueryParams,
  ApplicationListRequest,
} from "../../types/ApplicationListRequest";
import { ApplicationResponse } from "../../types/ApplicationResponse";
import ApplicationFilter, {
  FilterData,
} from "../ApplicationFilter/ApplicationFilter";
import ApplicationSearch from "../ApplicationSearch";
import WithdrawModal from "../WithdrawModal";

type ApplicationListProps = {
  showSearchSection: boolean;
  showNavigationSection: boolean;
  showCloneAction: boolean;
};

const ApplicationList: React.FunctionComponent<ApplicationListProps> = ({
  showSearchSection = false,
  showNavigationSection = true,
  showCloneAction = true,
}: ApplicationListProps) => {
  const [
    selectedApplication,
    setSelectedApplication,
  ] = useState<ApplicationResponse | null>(null);
  const applications = useSelector(getApplicationListSelector);
  const isLoading = useSelector(getLoadingSelector);
  const applicationCount = useSelector(getApplicationCountSelector);
  const errors = useSelector(getErrorMessageSelector);
  const cloneApplicationLoading = useSelector(
    getCloneApplicationLoadingSelector
  );
  const clonedApplicationId = useSelector(getClonedApplicationIdSelector);
  const loggedInUser = useSelector(getUserDataSelector);

  const dispatch = useApplicationListDispatch();
  const dispatchApplication = useApplicationFormDispatch();

  const history = useHistory();
  const location = useLocation();
  const queryParams: ApplicationListQueryParams = useMemo(() => {
    return qs.parse(location.search, {
      parseNumbers: true,
      arrayFormat: "comma",
    });
  }, [location.search]);
  const filterData = useMemo(
    () => ({
      search: queryParams.search ?? "",
      status: queryParams.status ?? "",
      user: queryParams.user
        ? {
            value: queryParams.user[0],
            label: queryParams.user[1],
          }
        : null,
      client: queryParams.client
        ? {
            value: queryParams.client[0],
            label: queryParams.client[1],
          }
        : null,
      page: queryParams.page ?? 1,
    }),
    [queryParams]
  );

  const closeWithdrawModalHandler = () => {
    setSelectedApplication(null);
  };

  const onWithdrawSuccessHandler = () => {
    dispatch(getApplicationList({ status: undefined, page: undefined }));
    setSelectedApplication(null);
  };

  const redirectPage = (overrideValues: ApplicationListRequest) => {
    const queryParams = {
      ...qs.parse(location.search),
      ...overrideValues,
    };
    history.push("?" + qs.stringify(queryParams));
  };

  const getApplications = useCallback(
    (queryParams: ApplicationListQueryParams) => {
      const params: ApplicationListRequest = {
        ...queryParams,
        client: queryParams?.client?.[0],
        user: queryParams?.user?.[0],
      };
      dispatch(getApplicationList(params));
    },
    [dispatch]
  );

  useEffect(() => {
    getApplications(queryParams);
  }, [getApplications, queryParams]);

  useDidMount(() => {
    getApplications(queryParams);
  });

  useEffect(() => {
    if (clonedApplicationId) {
      history.push(`/application/applications/${clonedApplicationId}/quotes`);
    }
  }, [clonedApplicationId, history]);

  const handleCloneApplication = async (id: string) => {
    dispatchApplication(cloneApplication(id));
  };

  const handleWithdrawApplication = (application: ApplicationResponse) => {
    setSelectedApplication(application);
  };

  const setQueryParams = (overrideValues: ApplicationListQueryParams) => {
    const newQueryParams = qs.stringify(
      {
        ...queryParams,
        ...overrideValues,
      },
      {
        arrayFormat: "comma",
      }
    );
    history.push("?" + newQueryParams);
  };

  const onFilterChangeHandler = (filterData: FilterData) => {
    setQueryParams({
      status: filterData.status || undefined,
      user: filterData.user
        ? [filterData.user?.value, filterData.user?.label]
        : undefined,
      client: filterData.client
        ? [filterData.client?.value, filterData.client?.label]
        : undefined,
      page: undefined,
    });
  };

  const renderApplicationIdColumn = (application: ApplicationResponse) => (
    <div
      style={{
        width: "75px",
      }}
    >
      <CLink
        to={`/application/applications/${application.id}/quotes`}
        className="btn-sm btn btn-link"
        data-testid={`application-${application.applicationNumber}`}
      >
        {application.applicationNumber}
      </CLink>
    </div>
  );

  const renderApplicantColumn = (application: ApplicationResponse) => (
    <div data-testid={`applicant-${application.applicationNumber}`}>
      {application.name}
    </div>
  );

  const getBadgeColor = (status: string): string => {
    let badgeColor = "default";
    for (const color in APPLICATION_STATUS_COLORS) {
      const statusFound = (APPLICATION_STATUS_COLORS[color] as string[]).find(
        (item) => {
          return item === status;
        }
      );
      if (statusFound) {
        badgeColor = color;
        break;
      }
    }

    return badgeColor;
  };

  const renderStatusColumn = (application: ApplicationResponse) => {
    const badgeColor = getBadgeColor(application.publicStatus);
    return (
      <Badge
        color={badgeColor as BADGE_COLORS}
        data-testid={`application-${application.id}-status`}
        text={APPLICATION_PUBLIC_STATUS_LABELS[application.publicStatus]}
      />
    );
  };

  const renderSecurityColumn = (application: ApplicationResponse) => {
    return (
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        data-testid={`application-${application.id}-security`}
      >
        {application.security?.name || ""}
      </div>
    );
  };

  const renderSubmittedTimeColumn = (application: ApplicationResponse) => {
    let submittedTime = "";
    if (application.submittedAt) {
      const submittedAt = new Date(application.submittedAt);
      submittedTime = dateFormat(submittedAt, "dd/MM/yyyy - kk:mm");
    }

    return <div>{submittedTime}</div>;
  };

  const renderActionColumn = (application: ApplicationResponse) => (
    <div>
      {showCloneAction &&
        application.status !== APPLICATION_PUBLIC_STATUSES.QUOTED && (
          <CButton
            className="btn-link btn-sm mr-1"
            type="button"
            onClick={() => handleCloneApplication(application.id)}
            data-testid={`application-${application.id}-clone`}
          >
            Clone
          </CButton>
        )}
      {(APPLICATION_STATUS_GROUP.LOCKED as string[]).includes(
        application.status
      ) !== true && (
        <CButton
          className="btn-link btn-sm"
          type="button"
          onClick={() => handleWithdrawApplication(application)}
          data-testid={`application-${application.id}-withdraw`}
        >
          Withdraw
        </CButton>
      )}
    </div>
  );

  const renderAmountFinancedColumn = (application: ApplicationResponse) => {
    return (
      <div data-testid={`application-${application.id}-amountFinanced`}>
        {application.quote ? (
          <NumberFormat
            thousandSeparator
            prefix={"$"}
            value={
              application.quote.amountFinanced
                ? application.quote.amountFinanced.toFixed(2)
                : 0
            }
            displayType="text"
            decimalScale={2}
            className="quest-amount-financed"
          />
        ) : (
          0
        )}
      </div>
    );
  };

  const columns: IDataTableColumn<ApplicationResponse>[] = [
    {
      selector: "applicationNumber",
      name: "Loan #",
      cell: renderApplicationIdColumn,
      sortable: true,
      width: "100px",
    },
    {
      selector: "name",
      name: "Applicant",
      cell: renderApplicantColumn,
      sortable: true,
      width: "175px",
    },
    {
      selector: "security.name",
      name: "Asset",
      cell: (item: ApplicationResponse) => renderSecurityColumn(item),
      sortable: false,
      width: "175px",
    },
    {
      selector: "submittedAt",
      name: "Submitted time",
      cell: (item: ApplicationResponse) => renderSubmittedTimeColumn(item),
      sortable: true,
    },
    {
      selector: "status",
      name: "Status",
      cell: (item: ApplicationResponse) => renderStatusColumn(item),
      sortable: true,
      width: "175px",
    },
    {
      selector: "quote.amountFinanced",
      name: "Amount financed",
      cell: (item: ApplicationResponse) => renderAmountFinancedColumn(item),
      sortable: false,
      right: true,
    },
    {
      selector: "actions",
      name: "Actions",
      cell: (item: ApplicationResponse) => renderActionColumn(item),
      sortable: false,
    },
  ];

  return (
    <>
      {showSearchSection && (
        <CRow className={`mb-4`} data-testid="application-search-container">
          <CCol xs={12}>
            <ApplicationSearch />
          </CCol>
        </CRow>
      )}
      {showNavigationSection && (
        <CRow className={`mb-4`}>
          <CCol xs={12}>
            <div className="quick-quote-new-app">
              <CLink to="/application/quotes/quick-quote">
                <QuestButton
                  className="big cta mr-3"
                  type="button"
                  data-testid="quick-quote-button"
                >
                  Quick Quote
                </QuestButton>
              </CLink>
              <CLink to="/application/applications/new">
                <QuestButton
                  className="big transparent"
                  type="button"
                  data-testid="new-application-button"
                >
                  New Application
                  <ReactSVG src={ArrowRedRight} />
                </QuestButton>
              </CLink>
            </div>
          </CCol>
        </CRow>
      )}
      <CRow>
        <CCol xs="12">
          <CCard className="quest-card">
            <CCardBody className="quest-datatable application-list">
              {!filterData.search && (
                <ApplicationFilter
                  filterData={filterData}
                  onFilterChange={onFilterChangeHandler}
                  showReferrerFilter={isAllowedAccess(
                    loggedInUser as UserData,
                    [PERMISSION_IAM["IAM.REFERRER.READ"]]
                  )}
                />
              )}
              {errors && typeof errors === "string" && (
                <CAlert color="danger">{errors}</CAlert>
              )}
              <DataTable
                className="application-table"
                noHeader
                responsive
                columns={columns}
                data={applications}
                sortServer
                onSort={(column, direction) => {
                  const order = parseSortingObject({
                    column: column.selector as string,
                    direction,
                  });
                  redirectPage({
                    page: undefined,
                    order,
                  });
                }}
                pagination
                paginationServer
                paginationDefaultPage={filterData.page}
                paginationTotalRows={applicationCount}
                paginationComponentOptions={{ noRowsPerPage: true }}
                progressPending={isLoading}
                onChangePage={(page) => {
                  redirectPage({
                    page,
                  });
                }}
              />
            </CCardBody>
            {cloneApplicationLoading && (
              <Loading text={"Cloning application. Please wait..."} />
            )}
          </CCard>
        </CCol>
      </CRow>
      <WithdrawModal
        application={selectedApplication}
        onClose={closeWithdrawModalHandler}
        onWithdrawSuccess={onWithdrawSuccessHandler}
      />
    </>
  );
};

export default ApplicationList;
