import React, { useCallback } from "react";
import { AxiosError } from "axios";
import { GroupBase, Options } from "react-select";
import ReactSelectAsync, { AsyncProps } from "react-select/async";
import { useDebouncedCallback } from "use-debounce";
import { processErrorMessage } from "../../error-handler/utils";
import { actionCreator, dispatch } from "../../notification";
import UserService from "../../user/services/UserService";

export type TUser = { label: string; value: number };
export type TUserQuery<T = TUser> = (
  keyword: string,
  cb: (options: Options<T>) => void
) => void;
type UserDropdownProps = {
  clientId?: number;
};

const UserDropdown: ReactSelectAsync = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: AsyncProps<Option, IsMulti, Group> & UserDropdownProps
) => {
  const { clientId } = props;
  const { useNotificationDispatch } = dispatch;
  const { setNotification } = actionCreator;
  const notifDispatch = useNotificationDispatch();
  const userQuery: TUserQuery = useCallback(
    async (keyword, cb) => {
      try {
        const users = await UserService.getUserList({ clientId, keyword });
        cb(
          users.data.map((user) => {
            return {
              label: `${user.firstName} ${user.lastName}`,
              value: user.id,
            };
          })
        );
      } catch (error) {
        const message = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            body: message,
            className: "qst-notif-danger",
          })
        );
      }
    },
    [notifDispatch, setNotification, clientId]
  );

  const debouncedUserQuery = useDebouncedCallback((input, cb) => {
    userQuery(input, cb);
  }, 700);

  return (
    <ReactSelectAsync
      key={clientId}
      placeholder="Filter by user"
      isClearable={true}
      defaultOptions={true}
      loadOptions={debouncedUserQuery}
      {...props}
    />
  );
};

export default UserDropdown;
