import React, { useMemo, useState } from "react";
import { CCol, CRow, CCard, CCardHeader, CCardBody } from "@coreui/react";
import { AxiosError } from "axios";
import _omit from "lodash/omit";
import _pick from "lodash/pick";
import qs from "query-string";
import { useHistory, useLocation, useParams } from "react-router";
import useDidMount from "../../../../common/hooks/useDidMount";
import { FormMode } from "../../../../common/types/Form";
import { processErrorMessage } from "../../../../error-handler/utils";
import {
  setNotification,
  unsetNotification,
} from "../../../../notification/actions/creators";
import { useNotificationDispatch } from "../../../../notification/dispatchers";
import RefUserForm from "../../../components/RefUserForm";
import { defaultRefUser } from "../../../constants/ref-user";
import UserService from "../../../services/UserService";
import {
  RefUserFormData,
  RefUserPayload,
  ValidRefUserFormData,
} from "../../../types/ref-user";
import "./index.scss";

type UrlParams = {
  userId: string;
};

const notifId = "create-ref-user";
const fieldKeys = Object.keys(defaultRefUser) as Array<keyof RefUserFormData>;
const RefUserAdmin: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = useParams<UrlParams>();
  const isNew = urlParams.userId === "new";
  const queryParams: { client?: [number, string] } = useMemo(() => {
    return qs.parse(location.search, {
      parseNumbers: true,
      arrayFormat: "comma",
    });
  }, [location.search]);
  const notifDispatch = useNotificationDispatch();
  const [refClient, setRefClient] = useState<RefUserFormData>({
    ...defaultRefUser,
    client: queryParams.client
      ? {
          value: queryParams.client[0],
          label: queryParams.client[1],
        }
      : null,
  });
  const [loading, setLoading] = useState(false);

  const getRefUser = async (id: number) => {
    try {
      setLoading(true);
      const { data } = await UserService.getRefUser(id);

      setRefClient({
        ..._pick(data, fieldKeys),
        client: data.client
          ? {
              value: data.client.id,
              label: data.client.name,
            }
          : null,
      });
    } catch (error) {
      const message = processErrorMessage(error as AxiosError);
      notifDispatch(
        setNotification({
          id: notifId,
          body: message,
          className: "qst-notif-danger",
        })
      );
    }
    setLoading(false);
  };

  const onSubmitHandler = async (data: ValidRefUserFormData) => {
    notifDispatch(unsetNotification(notifId));
    const payload: RefUserPayload = {
      ..._omit(data, ["client"]),
      clientId: data.client.value,
    };
    try {
      setLoading(true);
      let action: string;
      if (isNew) {
        await UserService.createRefUser(payload);
        action = "created";
      } else {
        await UserService.updateRefUser(+urlParams.userId, payload);
        action = "updated";
      }
      notifDispatch(
        setNotification({
          id: notifId,
          body: `Referrer Salesperson successfully ${action}.`,
          className: "qst-notif-success",
        })
      );
      history.push("/iam/ref-users");
    } catch (error) {
      const message = processErrorMessage(error as AxiosError);
      notifDispatch(
        setNotification({
          id: notifId,
          body: message,
          className: "qst-notif-danger",
        })
      );
    }
    setLoading(false);
  };

  useDidMount(() => {
    if (!isNew) {
      getRefUser(+urlParams.userId);
    }
  });

  return (
    <div className="ref-user-admin">
      <CRow className="quest-page-header-section">
        <CCol xs={12} className="align-items-center d-flex">
          <h2 className="quest-header-text">
            <span data-testid="ref-user-display-name">
              {isNew
                ? "New Referrer Salesperson"
                : `${refClient.firstName} ${refClient.lastName}`}
            </span>
          </h2>
        </CCol>
      </CRow>
      <CRow className="quest-details-content-section ref-user-admin-content">
        <CCol xl={12}>
          <CCard className="quest-card quest-form">
            <CCardHeader>
              <h3>Referrer Salesperson Admin</h3>
            </CCardHeader>
            <CCardBody>
              <RefUserForm
                data={refClient}
                loading={loading}
                mode={isNew ? FormMode.CREATE : FormMode.EDIT}
                onSubmit={onSubmitHandler}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default RefUserAdmin;
