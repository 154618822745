import * as qs from "query-string";
import { DATATABLE_MAX_ROWS } from "../../common/constants/datatable";
import APIService from "../../common/services/APIService";
import { ListResponse } from "../../common/types/ListResponse";
import { SingleResponse } from "../../common/types/SingleResponse";
import { getOffset } from "../../common/utils/dataTable";
import {
  RefClientListParams,
  RefClient,
  RefClientPayload,
} from "../types/ref-client";

class ClientService {
  public static async listClients(
    params?: RefClientListParams
  ): Promise<ListResponse<RefClient>> {
    const limit = params?.limit ?? DATATABLE_MAX_ROWS;
    const queryParams = qs.stringify({
      limit,
      offset: getOffset(params?.offset ?? 1, limit),
      keyword: params?.keyword,
      bdmId: params?.bdmId,
    });

    return await APIService.jsonRequest<ListResponse<RefClient>, unknown>(
      {
        method: "GET",
        path: "/iam/clients?" + queryParams,
      },
      true
    );
  }

  public static async listRefClients(
    params?: RefClientListParams
  ): Promise<ListResponse<RefClient>> {
    const limit = params?.limit ?? DATATABLE_MAX_ROWS;
    const queryParams = qs.stringify({
      limit,
      offset: getOffset(params?.offset ?? 1, limit),
      keyword: params?.keyword,
      bdmId: params?.bdmId,
    });

    return await APIService.jsonRequest<ListResponse<RefClient>, true>(
      {
        method: "GET",
        path: "/iam/clients/managed-clients?" + queryParams,
      },
      true
    );
  }

  public static async getRefClient(
    id: number
  ): Promise<SingleResponse<RefClient>> {
    return await APIService.jsonRequest<SingleResponse<RefClient>, true>(
      {
        method: "GET",
        path: "/iam/ref-clients/" + id,
      },
      true
    );
  }

  public static async createRefClient(
    clientData: RefClientPayload
  ): Promise<SingleResponse<RefClient>> {
    return await APIService.jsonRequest<
      SingleResponse<RefClient>,
      RefClientPayload
    >(
      {
        method: "POST",
        path: "/iam/ref-clients",
        data: clientData,
      },
      true
    );
  }

  public static async updateRefClient(
    id: number,
    clientData: RefClientPayload
  ): Promise<SingleResponse<RefClient>> {
    return await APIService.jsonRequest<
      SingleResponse<RefClient>,
      RefClientPayload
    >(
      {
        method: "PATCH",
        path: "/iam/ref-clients/" + id,
        data: clientData,
      },
      true
    );
  }

  public static async listManagedClients(
    params?: RefClientListParams
  ): Promise<ListResponse<RefClient>> {
    const limit = params?.limit ?? DATATABLE_MAX_ROWS;
    const includeDerivedAccess = params?.includeDerivedAccess ?? true;
    const queryParams = qs.stringify({
      limit,
      includeDerivedAccess,
      offset: getOffset(params?.offset ?? 1, limit),
      keyword: params?.keyword,
      bdmId: params?.bdmId,
    });

    return await APIService.jsonRequest<ListResponse<RefClient>, true>(
      {
        method: "GET",
        path: "/iam/clients/managed-clients?" + queryParams,
      },
      true
    );
  }
}

export default ClientService;
