import { REPAYMENT_TERM_OPTIONS } from "../../application/constants/repaymentTermOptions";

export const PAYMENT_CYCLE_OPTIONS = [
  {
    value: REPAYMENT_TERM_OPTIONS.MONTHLY,
    label: "Monthly",
  },
  {
    value: REPAYMENT_TERM_OPTIONS.WEEKLY,
    label: "Weekly",
  },
];
